import moment from 'moment'

jQuery(document).ready(function ($) {

  // const $cursor = $('#cursor');

  // $(document).on('mousemove', function (e) {
  //   $cursor.css({
  //     left: e.pageX,
  //     top: e.pageY
  //   });
  // });


  // MOBILE MENU
  $('.mobile-menu-icon').on('click', function () {
    $("#nav-menu").toggleClass('active')
    $("header").toggleClass('active')
    $(".line").toggleClass('active')
  })

  $('.nav-item').on('click', function () {
    if ($('#nav-menu').hasClass('active')) {
      $("#nav-menu").toggleClass('active')
      $("header").toggleClass('active')
      $(".line").toggleClass('active')
    }
  })

  // VIDEO OVERLAY
  $('.hover-overlay').on('click', function () {
    $(this).addClass('hidden');
    $(this).siblings('img').addClass('hidden');
  })

  // Tourdate Import
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_14703843/events?app_id=45PRESS_kamero_marlowe',
    method: 'GET',
    dataType: 'json',
    error: function () {
      alert('Error fetching events!');
    },
    success: function (data) {
      const events = $('#tour-dates');
      let html = '';
      let eventCount = 0;

      if (data.length) {
        data.forEach(function (event) {
          eventCount++;
          html += '<div class="event-group">';
          html += '<div class="event-date-venue-group">';
          html += '<div class="event-date">' + moment(event.datetime).format('MMM DD').toUpperCase() + '</div>';
          html += '<div class="event-venue">' + event.venue.name.toUpperCase() + '</div>';
          html += '</div>';
          html += '<div class="event-location">' + event.venue.location.toUpperCase() + '</div>';
          html += '<div class="event-links">';

          event.offers.forEach(function (offer) {
            html += '<a href="' + offer.url + '" target="_blank" class="link btn">' + offer.type.toUpperCase() + '</a>';
          });

          html += '</div>';
          html += '</div>';
        });

        events.html(html);

      } else {
        events.html('<span class="no-events">Check back soon for new shows!</span>');
      }

      if (eventCount < 9) {
        $("#toggle-dates").hide();
      }
    }
  });

  // FORM CODE
  $("#kameron-marlowe-website-newsletter-signup").on("submit", function (e) {
    e.preventDefault();
    var data = $(this).serialize();
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('#kameron-marlowe-website-newsletter-signup').html('<p class="thanks">THANKS FOR SIGNING UP!</p>')
      },
      error: function (err) {
        console.log(err)
        alert("An error has occurred!");
      }
    });
  });

  $('body').on('change', '.mailing-list-id', function () {
    $("#ts-for-" + $(this).attr('id')).prop("checked", $(this).is(':checked'));
  });

  // NAVIGATION
  function toggleNavigation(activeElement) {
    $("#hero").toggleClass("active", activeElement === "#hero");
    $("#tour").toggleClass("active", activeElement === "#tour");
    $("#music").toggleClass("active", activeElement === "#music");
    $("#video").toggleClass("active", activeElement === "#video");
    $("#signup").toggleClass("active", activeElement === "#signup");

    // Apply dark theme on load
    if (activeElement === "#tour" || activeElement === "#signup") {
      $('body').addClass('dark');
    } else {
      $('body').removeClass('dark');
    }
  }

  // Handle navigation clicks
  $(".set-nav").on('click', function () {
    const navId = $(this).attr('href');
    toggleNavigation(navId);
  });

  // Apply dark theme and active section on page load (if hash exists)
  $(document).ready(function () {
    if (window.location.hash) {
      toggleNavigation(window.location.hash);
    }
  });

});